import { FC, ReactNode } from "react";
import "./Heading2.scss";

interface Heading2Props {
  children: ReactNode;
  color?: "default" | "danger" | "light";
  className?: string;
}

export const Heading2: FC<Heading2Props> = ({
  children,
  className: _className,
  color = "default",
}) => {
  let className = _className + " " + "heading2";
  switch (color) {
    case "danger":
      className += " heading2--danger";
      break;
    case "light":
      className += " heading2--light";
      break;
  }

  return <h2 className={className}>{children}</h2>;
};
