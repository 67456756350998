import React, {
  FC,
  ReactNode,
  useState,
  useCallback,
  ChangeEvent,
} from "react";

import { Section } from "../../UI/Section/Section";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { CTA } from "../../UI/CTA/CTA";
import "./Order.scss";
import axios from "axios";
import certificate from "../../assets/certificate.jpeg";
import ReactDOM from "react-dom";

interface OrderProps {
  children?: ReactNode;
  selectedNumber: number;
  name: string;
  email: string;
  setEmail: (v: string) => void;
  setName: (v: string) => void;
  popup: (t: string, e?: boolean) => void;
  setBuyerAddress1: (v: string) => void;
  buyerAddress1: string;
  setBuyerAddress2: (v: string) => void;
  buyerAddress2: string;
  buyerName: string;
  buyerSurname: string;
  setBuyerName: (v: string) => void;
  setBuyerSurname: (v: string) => void;
}

export const Order: FC<OrderProps> = ({
  children,
  selectedNumber,
  email,
  setEmail,
  name,
  setName,
  popup,
  setBuyerAddress1,
  setBuyerAddress2,
  setBuyerName,
  setBuyerSurname,
  buyerAddress1,
  buyerAddress2,
  buyerName,
  buyerSurname,
}) => {
  const [buttonWasClicked, setButtonWasClicked] = useState(false);
  const [emailChangeHappened, setEmailChangeHappened] = useState(false);
  const [nameChangeHappened, setNameChangeHappened] = useState(false);
  const [addressPopupActive, setAddressPopupActive] = useState(false);

  const emailChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!emailChangeHappened) {
        gtag("event", "email_input", {});
        setEmailChangeHappened(true);
      }
      setEmail(e.target.value);
    },
    [emailChangeHappened, setEmail]
  );
  const nameChangeCallback = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!nameChangeHappened) {
        gtag("event", "name_input", {});
        setNameChangeHappened(true);
      }
      setName(e.target.value);
    },
    [nameChangeHappened, setName]
  );

  const onOrderCtaClick = () => {
    gtag("event", "cta_pressed", {
      id: "6",
    });
    if (!name || !email) {
      popup("Prvně musíš vyplnit věnování a e-mail!");
      return;
    }
    setAddressPopupActive(true);
  };

  return (
    <Section className="Order">
      <div className="Order__item" id="order">
        <div className="Order__item__text">S věnováním pro:</div>
        <input
          value={name}
          onChange={nameChangeCallback}
          type="text"
          className="Order__item__input"
        ></input>
      </div>
      <div className="Order__item">
        <div className="Order__item__text">
          E-mail pro certifikát tohodle epickýho nákupu:
        </div>
        <input
          value={email}
          onChange={emailChangeCallback}
          type="text"
          className="Order__item__input"
        ></input>
      </div>
      <p className="Order__agreement">
        Kliknutím na tlačítko souhlasíš s{" "}
        <a target="_blank" href="terms.pdf">
          obchodními podmínkami
        </a>{" "}
        a{" "}
        <a target="_blank" href="GDPR.pdf">
          ochranou osobních údajů
        </a>
      </p>
      <CTA onClick={onOrderCtaClick} className="Order__CTA">
        <span>Koupit</span> NIC ZA {selectedNumber} KORUN<br></br>
        <span>#nic4flex #kupnic4flex #kupnic4life #fame4nic #flexnavnuka</span>
      </CTA>
      <Heading2 className="Order__getCertificate">
        A ZÍSKEJ TEN ZATRACENEJ CERTIFIKÁT ŽE SIS KOUPIL TOTÁLNÍ NIC!
      </Heading2>
      <img className="Order__certificate" src={certificate}></img>
      {addressPopupActive &&
        ReactDOM.createPortal(
          <div
            className="Order__addressPopup"
            onClick={() => {
              setAddressPopupActive(false);
            }}
          >
            <div
              className="Order__addressPopup__content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <p className="Order__addressPopup__heading">
                Informace o nakupujícím
              </p>
              <div className="Order__item">
                <div className="Order__item__text">Jméno</div>
                <input
                  value={buyerName}
                  onChange={(e) => {
                    setBuyerName(e.target.value);
                  }}
                  type="text"
                  className="Order__item__input"
                  placeholder="Froněk"
                ></input>
              </div>
              <div className="Order__item">
                <div className="Order__item__text">Příjmení</div>
                <input
                  value={buyerSurname}
                  onChange={(e) => {
                    setBuyerSurname(e.target.value);
                  }}
                  type="text"
                  className="Order__item__input"
                  placeholder="Veselý"
                ></input>
              </div>
              <div className="Order__item">
                <div className="Order__item__text">Město, PSČ</div>
                <input
                  value={buyerAddress1}
                  onChange={(e) => {
                    setBuyerAddress1(e.target.value);
                  }}
                  type="text"
                  className="Order__item__input"
                  placeholder={`Valetice 539 02`}
                ></input>
              </div>
              <div className="Order__item">
                <div className="Order__item__text">Ulice, číslo popisné</div>
                <input
                  value={buyerAddress2}
                  onChange={(e) => {
                    setBuyerAddress2(e.target.value);
                  }}
                  type="text"
                  className="Order__item__input"
                  placeholder={`Třeskoprsky ${selectedNumber}`}
                ></input>
              </div>
              <CTA
                className="Order__addressPopup__CTA"
                onClick={() => {
                  if (buttonWasClicked) {
                    return;
                  }
                  if (
                    buyerAddress1.length < 2 ||
                    buyerAddress2.length < 2 ||
                    buyerName.length < 2 ||
                    buyerSurname.length < 2
                  ) {
                    popup(
                      "Prosím zadejte adresu (oba dva řádky), jméno, příjmení.",
                      true
                    );
                    return;
                  }
                  setButtonWasClicked(true);
                  axios
                    .post(
                      "https://faas-fra1-afec6ce7.doserverless.co/api/v1/web/fn-e7dcff30-7e37-43f9-be6e-e68506e2c0ff/nicshop/index/create-checkout-session",
                      {
                        name: name,
                        email: email,
                        price: selectedNumber,
                        buyername: buyerName,
                        buyerSurname: buyerSurname,
                        buyerAddress1: buyerAddress1,
                        buyerAddress2: buyerAddress2,
                      }
                    )
                    .then((r) => {
                      setAddressPopupActive(false);
                      if (!r.data.url) {
                        popup(
                          "Nastala náhodná chyba. Nic se neděje, zkuste to prosím později.",
                          true
                        );
                        setButtonWasClicked(false);
                      }
                      gtag("event", "purchase", {
                        value: selectedNumber,
                        currency: "CZK",
                      });
                      fbq("track", "Purchase", {
                        currency: "CZK",
                        value: selectedNumber,
                      });
                      window.location.replace(r.data.url);
                    })
                    .catch((e) => {
                      setButtonWasClicked(false);
                      setAddressPopupActive(false);
                      if (
                        e &&
                        e.response &&
                        e.response.data &&
                        e.response.data.err
                      ) {
                        const err = e.response.data.err as string;
                        if (err === "email") {
                          popup(
                            "Zkontrolujte, zda jste správně zadali email!",
                            true
                          );
                        } else if (err === "name") {
                          popup(
                            "Zkontrolujte, zda je jméno zadané správně!",
                            true
                          );
                        } else if (err === "price") {
                          popup(
                            "Minimální cena je 40 Kč :(",
                            true
                          );
                        } else {
                          popup(
                            "Nastala náhodná chyba. Nic se neděje, zkuste to prosím později.",
                            true
                          );
                        }
                      }
                    });
                }}
              >
                {buttonWasClicked ? "Čekejte, prosím" :  "Odeslat objednávku"}
              </CTA>
            </div>
          </div>,
          document.body
        )}
    </Section>
  );
};
