import { FC, ReactNode, useState, useEffect } from "react";
import "./NumberInput.scss";
import { Heading3 } from "../../UI/Heading3/Heading3";

interface NumberInputProps {
    children?: ReactNode;
    onChange: (v: number | null) => void;
    value: number | null;
}

export const NumberInput: FC<NumberInputProps> = ({ children, value, onChange }) => {

    const [cachedValue, setCachedValue] = useState<number | null>(69);
    let inputClassName = "NumberInput__input";
    if (value && value < 40) {
        inputClassName += " NumberInput__input--error"
    }

    return (
        <div className="NumberInput">
            <Heading3>ZADEJ NEJLEPŠÍ ČÍSLO: </Heading3>
            <input 
                onFocus={() => {
                    setCachedValue(value ? +value : null);
                    onChange(null);
                }}
                onBlur={() => {
                    onChange(cachedValue);
                }}
                value={value ? value : ''}
                onChange={
                    (e) => {
                        // if (+e.target.value >= 1) {
                            setCachedValue(+e.target.value);
                            onChange(+e.target.value);
                        // }
                    }
                }
            type="text" className={inputClassName}></input>
            <p className="NumberInput__minimumPrice">Minimální číslo je 40</p>
        </div>
    );
};
