import { FC, ReactNode } from "react";
import "./CTA.scss";

interface CTAProps {
  children: ReactNode;
  onClick: () => any;
  className?: string;
}

export const CTA: FC<CTAProps> = ({ children, onClick, className }) => {
  return (
    <button className={className + " cta"} onClick={onClick}>
      {children}
    </button>
  );
};
