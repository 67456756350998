import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Heading3 } from "../../UI/Heading3/Heading3";
import { Heading2 } from "../../UI/Heading2/Heading2";
import "./Nothing.scss";

interface NothingProps {
  children?: ReactNode;
  selectedNumber: number;
}

export const Nothing: FC<NothingProps> = ({ children, selectedNumber }) => {
  return (
    <Section className="Nothing">
        <Heading3 className="Nothing__nothing">
            UŽ TU NIC VÍC NENÍ.
        </Heading3>
        <Heading2 className="Nothing__surprise">
            JEN TVOJE NIC ZA<br></br>EXKLUZIVNÍCH<br></br><span>{selectedNumber} KORUN</span>
            <div className="Nothing__surprise__reaction">Ó můj bože!! 😱😱</div>
        </Heading2>
        <div className="Nothing__emoji">
            ✨
        </div>
    </Section>
  );
};
