import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import { App } from "./App";
import reportWebVitals from "./reportWebVitals";

function addScript(script: string, src?: string) {
  var scriptElm = document.createElement("script");
  if (src) {
    scriptElm.setAttribute("src", src);
  }
  var inlineCode = document.createTextNode(script);
  scriptElm.appendChild(inlineCode);
  document.head.append(scriptElm);
}
if (document.cookie.indexOf("cookiebar=CookieAllowed") >= 0) {
  // HOTJAR
  addScript(
    `
    (function(h,o,t,j,a,r){
      h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
      h._hjSettings={hjid:3255554,hjsv:6};
      a=o.getElementsByTagName('head')[0];
      r=o.createElement('script');r.async=1;
      r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
      a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `
  );
  // GOOGLE ANALYTICS
  addScript(
    "",
    "https://www.googletagmanager.com/gtag/js?id=G-3G4KS03GLX%22%3Es"
  );
  addScript(
    `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
    
      gtag('config', 'G-3G4KS03GLX');
    `
  );
  // PIXEL
  addScript(
    `
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', '1115948449002026');
    fbq('track', 'PageView');`
  );
} else {
  addScript(`function gtag(){}`);
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
