import { FC, ReactNode } from "react";
import "./Heading3.scss";

export interface Heading3Props {
  children: ReactNode;
  color?: "default" | "danger" | "light";
  align?: "left" | "center";
  className?: string;
}

export const Heading3: FC<Heading3Props> = ({
  children,
  color = "default",
  align = "left",
  className: _className
}) => {
  let className = _className + " " + "heading3";
  switch (color) {
    case "danger":
      className += " heading3--danger";
      break;
    case "light":
      className += " heading3--light";
      break;
  }

  if (align === "center") {
    className += " heading3--center";
  }

  return <h3 className={className}>{children}</h3>;
};
