import { forwardRef, ReactNode } from "react";
import "./Section.scss";

interface SectionProps {
  children: ReactNode;
  className?: string;
}

export const Section = forwardRef<HTMLElement, SectionProps>(
  ({ children, className }, ref) => {
    const sectionClassName = `section ${className}`;
    return (
      <section ref={ref} className={sectionClassName}>
        {children}
      </section>
    );
  }
);
