import { FC, ReactNode } from "react";
import "./Paragraph.scss";

interface ParagraphProps {
  children: ReactNode;
  align?: "left" | "center";
  size?: "regular" | "small";
  className?: string;
}

export const Paragraph: FC<ParagraphProps> = ({
  children,
  align = "left",
  size,
  className: _className
}) => {
  let className = _className + " " + "paragraph";
  if (align === "center") {
    className += " paragraph--center";
  }

  if (size === "small") {
    className += " paragraph--small";
  }

  return <p className={className}>{children}</p>;
};
