import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Heading1 } from "../../UI/Heading1/Heading1";
import { CTA } from "../../UI/CTA/CTA";
import "./Header.scss";
import { NumberInput } from "../../components/NumberInput/NumberInput";
import arrow from '../../assets/arrow.svg';

interface HeaderProps {
  children?: ReactNode;
  onChange: (v: number | null) => void;
  selectedNumber: number | null;
  lastAcceptableValue: number;
}

export const Header: FC<HeaderProps> = ({ children, onChange, selectedNumber, lastAcceptableValue }) => {
  return (
    <Section className="Header">
         <Heading1 className="Header__heading">
          NIC SHOP
        </Heading1>
        <NumberInput value={selectedNumber} onChange={(v: number | null) => {
          onChange(v);
        }}>

        </NumberInput>
        <CTA className="Header__CTA"
        onClick={() => {
          gtag("event", "cta_pressed", {
                id: "1",
          });
          const order = document.getElementById('order');
          order?.scrollIntoView({behavior: 'smooth'});
        }}>
            KUP NIC ZA JEDINEČNOU CENU {lastAcceptableValue}Kč!
        </CTA>
        <img className="Header__arrow" alt="Arrow" src={arrow}></img>
    </Section>
  );
};
