//LIB
import React from 'react';
import { CTA } from '../../UI/CTA/CTA';

//LOCAL
import './YouHere.scss';

interface YouHereProps {

}

export const YouHere: React.FC<YouHereProps> = () => {
    return <div className="YouHere">
        <p className="YouHere__p">Ještě tu jsi?</p>
        <p className="YouHere__p">Dobře, pojď blíž...</p>
        <p className="YouHere__p">Ještě blíž.</p>
        <p className="YouHere__p">Chci ti říct ještě jednu věc.</p>
        <p className="YouHere__p">Máš pravdu. Je to trochu hloupý. Ale to je součást této výzvy.</p>
        <p className="YouHere__p">Certifikát o koupi ničeho, který za chvíli dostaneš, není jen kus papíru.</p>
        <p className="YouHere__p">Je to připomínka, že máme poodstoupit od nátlaku konzumovat a hromadit věci.</p>
        <p className="YouHere__p">Je to symbol rebelie proti idei, že si musíme kupovat věci, abychom byli šťastní a naplnění.</p>
        <p className="YouHere__p">Navíc! Je to skvělý, zábavný a unikátní ice-breaker! Kdo by nechtěl slyšet příběh o tom, jak sis koupil nic?</p>
        <p className="YouHere__p">Nejlepší na tom je, že si sám můžeš vybrat cenu! Můžeš to udělat jakkoli cenově dostupné nebo luxusní jak zrovna chceš!</p>
        <p className="YouHere__p">Je to určitě jiný typ nákupu. A ne každý v tom uvidí hodnotu.</p>
        <p className="YouHere__p">Podívej se na to takhle:</p>
        <p className="YouHere__p">To, že sis koupil nic, ukazuje, že si vážíš zážitků a vztahů s lidmi co máš rád mnohem více, než materiálních věcí.</p>
        <p className="YouHere__p">Je to způsob, jakým dát najevo, že nepotřebujeme hmotné věci, abychom byli šťastní.</p>
        <p className="YouHere__p">A, konec konců, je to zanedbatelná cena, kterou platíš za projev své nezávislosti na kultuře konzumu.</p>
        <p className="YouHere__p">Získáním certifikátu se stáváš součástí komunity podobně smýšlejících lidí, kteří si více cení vztahů a prožitků, než věcí.</p>
        <p className="YouHere__p">Získáš vtipné a jedinečné téma ke konverzaci.</p>
        <p className="YouHere__p">Jsi připravený být součást této exkluzivní skupiny lidí?</p>
        <p className="YouHere__p">Jsi připravený udělat odvážný projev koupě ničeho?</p>
        <p className="YouHere__p">Čas to udělat - je teď.</p>
        <p className="YouHere__p">Cenu zvolíš nahoře na stránce.</p>
        <p className="YouHere__p">Jakkoli dostupné, jakkoli luxusní chceš. </p>
        <CTA className="YouHere__CTA" onClick={() => {
            gtag("event", "cta_pressed", {
                id: "7",
            });
            const order = document.getElementById('order');
            order?.scrollIntoView({ behavior: 'smooth' });
        }}>Říkám ano</CTA>
    </div>
};