import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Heading3 } from "../../UI/Heading3/Heading3";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import "./Offer.scss";

interface OfferProps {
  children?: ReactNode;
  selectedNumber?: number | null;
}

export const Offer: FC<OfferProps> = ({ children }) => {
  return (
    <Section className="Offer">
        <Paragraph className="Offer__text">
        Počkat! Chci ti něco říct! Tohle je opravdu jedinečná nabídka!<br></br><br></br>

        • Vytrolíš celej kapitalismus. <br></br>
        • Vytrolíš kompletně byznys. <br></br>
        • Ty kurde VYTROLÍŠ CELOU EKONOMIKU <br></br>
        <br></br>
        když si koupíš nic! Tady není o čem, jedinečná šance, tahle kravina tu už dlouho nebude a ty jsi na tomhle webu. V tenhle čas. Teď je ta možnost udělat nemyslitelné. Změnit to, jak tě vnímá okolí, rozesmát je, přinést jim štěstí a překvapení, které opravdu nečekali. Copak by někdo čekal nic? Odpověz si! Tímhle jim vytřeš zrak. Tímhle jim ukážeš co v tobě je, že s tebou není nuda, že dokážeš jít životem ladně a s humorem. Protože to je to, kdo jsi.
        </Paragraph>
        <Heading3 className="Offer__challenge">
            A CO UDĚLÁŠ SE SVÝM NIC TY?
        </Heading3>
    </Section>
  );
};