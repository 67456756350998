import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Heading3 } from "../../UI/Heading3/Heading3";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { CTA } from "../../UI/CTA/CTA";
import "./Review.scss";

interface ReviewProps {
  children?: ReactNode;
  selectedNumber: number;
}

export const Review: FC<ReviewProps> = ({ children, selectedNumber }) => {
  return (
    <Section className="Review">
       <Heading3 className="Review__heading">recenze</Heading3>
       <Paragraph className="Review__quote">
            <div className="Review__quote__text"> “Nevytřu s tím podlahu, neuvařím s tím roštěnku, spálit se to taky nedá, je to absolutně k ničemu!”</div>
            <div className="Review__quote__name">Gerda, {selectedNumber} let</div>
       </Paragraph>
       <div className="Review__plotTwist">
            Jenže Gerda zapoměla že je teď slavná v síňy slávy za<br></br><span>N I C ⭐</span>
       </div>
       <Paragraph className="Review__quote">
            <div className="Review__quote__text"> “Přítel mi nic nekoupil!” </div>
            <div className="Review__quote__name">Alžběta, prý “nechtěla nic”</div>
       </Paragraph>
       <Paragraph className="Review__quote">
            <div className="Review__quote__text"> “Díra by bylo něco, ale tam nebylo nic.”</div>
            <div className="Review__quote__name">Kamenožrout</div>
       </Paragraph>
       <Heading3 className="Review__stoneEater">
            BUĎ JAKO KAMENOŽROUT
       </Heading3>
       <CTA onClick={() => {const order = document.getElementById('order');
          gtag("event", "cta_pressed", {
               id: "5",
          });
          order?.scrollIntoView({behavior: 'smooth'});}} className="Review__CTA">
            KUP SI NIC
       </CTA>
    </Section>
  );
};
