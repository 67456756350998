import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Heading2 } from "../../UI/Heading2/Heading2";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import "./Magie.scss";

interface MagieProps {
  children?: ReactNode;
  selectedNumber: number;
}

export const Magie: FC<MagieProps> = ({ children, selectedNumber }) => {
  return (
    <Section className="Magie">
         <Heading2 className="Magie__heading">
          MAGIE
        </Heading2>
        <Paragraph className="Magie__p">
          Počítej se mnou!
        </Paragraph>
        <Paragraph className="Magie__p">
          Přičti 2<br></br>
          {selectedNumber} + 2 = {selectedNumber + 2}
        </Paragraph>
        <Paragraph className="Magie__p">
          Vynásob 2x<br></br>
          {selectedNumber + 2} * 2 = {(selectedNumber + 2)*2}
        </Paragraph>
        <Paragraph className="Magie__p">
          Odečti 2<br></br>
          {(selectedNumber + 2)*2} - 2 = {(selectedNumber + 2)*2 - 2}
        </Paragraph>
        <Paragraph className="Magie__p">
          Vyděl 2<br></br>
          {(selectedNumber + 2)*2 - 2} % 2 = {((selectedNumber + 2)*2 - 2)/2}
        </Paragraph>
        <Paragraph className="Magie__p">
          Odečti tvoje číslo {selectedNumber}<br></br>
          {((selectedNumber + 2)*2 - 2)/2} - {selectedNumber} = {((selectedNumber + 2)*2 - 2)/2 - selectedNumber}
        </Paragraph>
        <Paragraph className="Magie__p Magie__p_result_text">
            Výsledek je 1! Tohle je totiž jen 1 šance koupit 1x nic za cenu pouhých
        </Paragraph>
        <Paragraph className="Magie__p Magie__p_result">
            {selectedNumber} Kč
        </Paragraph>
    </Section>
  );
};
