import { FC, ReactNode, useEffect, useState } from "react";
import "./AgressiveTimer.scss";

interface AgressiveTimerProps {
  children?: ReactNode;
}

export const AgressiveTimer: FC<AgressiveTimerProps> = ({ children }) => {
  const [timeInSeconds, setTimeInSeconds] = useState(10*60);
  useEffect(() => {
    const iNumber = setInterval(() => {setTimeInSeconds(t => t-1)}, 1000)
    return () => {
      clearInterval(iNumber);
    }
  }, []);

  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;
  return (
    <div className="AgressiveTimer">
      Zbývá {minutes}:{seconds} minut než tahle stránka zmizí a už si nikdy nebudeš moct koupit nic! 😭😭😭
    </div>
  );
};
