import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { CTA } from "../../UI/CTA/CTA";
import "./CantWait.scss";

interface CantWaitProps {
  children?: ReactNode;
  selectedNumber: number;
}

export const CantWait: FC<CantWaitProps> = ({ children, selectedNumber }) => {
  return (
    <Section className="CantWait">
        <CTA className="CantWait__CTA" onClick={() => {
          gtag("event", "cta_pressed", {
              id: "3",
          });
          const order = document.getElementById('order');
          order?.scrollIntoView({behavior: 'smooth'});}}>
            UŽ SE NEMŮŽU DOČKAT AŽ<br></br>
            <span className="CantWait__span">😱 KOUPÍM NIC 😱</span>
        </CTA>
        <Paragraph className="CantWait__p CantWait__p_challenge">
            KLIKNI NA TO TLAČÍTKO A KUP SI NIIIIC!!!
        </Paragraph>
        <Paragraph className="CantWait__p CantWait__p_price">
            za {selectedNumber} kč
        </Paragraph>
    </Section>
  );
};
