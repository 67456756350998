import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { CTA } from "../../UI/CTA/CTA";
import "./Grandfather.scss";
import { Banner } from "../../components/Banner/Banner";
import grandfather from '../../assets/grandfather.jpeg';

interface GrandfatherProps {
  children?: ReactNode;
  selectedNumber?: number | null;
}

export const Grandfather: FC<GrandfatherProps> = ({ children, selectedNumber }) => {
  return (
    <Section className="Grandfather">
        <img className="Grandfather__img" src={grandfather} alt="Grandfather with his grandson, happy"></img>
        <Banner className="Grandfather__banner Grandfather__banner_bottom">
            <div className="Grandfather__banner_bottom__text">
                “Můj dědeček je nejlepší na světě, protože si koupil nic za {selectedNumber}Kč. Až vyrostu, chci mít taky {selectedNumber}Kč.”
            </div>
            <div className="Grandfather__banner_bottom__name">
                Tvůj vnuk, 20{selectedNumber}
            </div>
        </Banner>
        <Banner className="Grandfather__banner Grandfather__banner_right">
            <div className="Grandfather__banner_right__text">
            POCHLUB SE JEDNOU VNUKOVI ŽE SIS KOUPIL NIC ZA {selectedNumber} KORUN!</div>
        </Banner>
        <CTA onClick={() => {
          gtag("event", "cta_pressed", {
              id: "2",
          });
          const order = document.getElementById('order');
          order?.scrollIntoView({behavior: 'smooth'});}}>
            ! KUP NIC NA FLEX VNUKOVI !
        </CTA>
    </Section>
  );
};
