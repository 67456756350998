import { FC, ReactNode } from "react";

import { Section } from "../../UI/Section/Section";
import { Paragraph } from "../../UI/Paragraph/Paragraph";
import { CTA } from "../../UI/CTA/CTA";
import "./HallOfFame.scss";
import { Heading1 } from "../../UI/Heading1/Heading1";

interface HallOfFameProps {
  children?: ReactNode;
  selectedNumber: number;
}

export const HallOfFame: FC<HallOfFameProps> = ({
  children,
  selectedNumber,
}) => {
  return (
    <Section className="HallOfFame">
      <Heading1 className="HallOfFame__heading">
        SÍŇ SLÁVY ZA<br></br>
        <span>NIC</span>
      </Heading1>
      <div className="HallOfFame__list">
        <div className="HallOfFame__entry">
          <div className="HallOfFame__entry__name">Alphadon</div>
          <div className="HallOfFame__entry__text">KOUPIL NIC</div>
          <div className="HallOfFame__entry__price">za 666 Kč</div>
        </div>
        <div className="HallOfFame__entry">
          <div className="HallOfFame__entry__name">Merlin</div>
          <div className="HallOfFame__entry__text">KOUPIL NIC</div>
          <div className="HallOfFame__entry__price">za 42 Kč</div>
        </div>
        <div className="HallOfFame__entry">
          <div className="HallOfFame__entry__name">Hauptbobr</div>
          <div className="HallOfFame__entry__text">KOUPIL NIC</div>
          <div className="HallOfFame__entry__price">za 40 Kč</div>
        </div>
      </div>
      <Paragraph className="HallOfFame__p">
        Toto je zatím celá síň slávy
      </Paragraph>
      <CTA
        onClick={() => {
          gtag("event", "cta_pressed", {
            id: "4",
          });
          const order = document.getElementById("order");
          order?.scrollIntoView({ behavior: "smooth" });
        }}
        className="HallOfFame__CTA"
      >
        <div className="HallOfFame__CTA__text">
          VSTOUPIT DO SÍNĚ SLÁVY TÍM, ŽE SI KOUPÍM NIC ZA {selectedNumber}{" "}
          KORUN!
        </div>
        <div className="HallOfFame__CTA__background"></div>
      </CTA>
      <Paragraph className="HallOfFame__youAreBest">
        JSI NEJLEPŠÍ <br></br>🎉🎉🎉🎉🎉🎉
        <div className="HallOfFame__youAreBest__emoji HallOfFame__youAreBest__emoji_left">
          🎉
        </div>
        <div className="HallOfFame__youAreBest__emoji HallOfFame__youAreBest__emoji_right">
          🎉
        </div>
      </Paragraph>
    </Section>
  );
};
