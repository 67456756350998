import { FC, ReactNode } from "react";
import "./Banner.scss";

interface BannerProps {
  children?: ReactNode;
  className?: string;
}

export const Banner: FC<BannerProps> = ({ children, className }) => {
  return (
    <div className={className + " " + "Banner"}>
        {children}
    </div>
  );
};
