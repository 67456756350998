import { useState, useEffect, useCallback } from "react";

// SECTIONS
import { Magie } from "./sections/Magie/Magie";
import { Header } from "./sections/Header/Header";
import { CantWait } from "./sections/CantWait/CantWait";
import { Grandfather } from "./sections/Grandfather/Grandfather";
import { HallOfFame } from "./sections/HallOfFame/HallOfFame";
import { Nothing } from "./sections/Nothing/Nothing";
import { Order } from "./sections/Order/Order";
import { Review } from "./sections/Review/Review";
import { Offer } from "./sections/Offer/Offer";

// COMPONENTS
import { AgressiveTimer } from "./components/AgressiveTimer/AgressiveTimer";
import Popup from './UI/Popup/Popup';
import { YouHere } from "./sections/YouHere/YouHere";

let called = 0;

export const App = () => {
  const [selectedNumber, setSelectedNumber] = useState<number | null>(69);
  const [lastAcceptableValue, setLastAcceptableValue] = useState<number>(69);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [buyerAddress1, setBuyerAddress1] = useState('');
  const [buyerAddress2, setBuyerAddress2] = useState('');
  const [buyerName, setBuyerName] = useState('');
  const [buyerSurname, setBuyerSurname] = useState('');

  const [popupText, setPopupText] = useState<string | null>(null);
  const [popupError, setPopupError] = useState<boolean>(false);

  const popup = useCallback((text: string, isError: boolean = false) => {
    setPopupError(isError);
    setPopupText(text);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    if (called === 0) {
      if (success === "true") {
        setTimeout(() => {popup("Děkujeme za Vaši objednávku. Do 48 hodin Vám zašleme certifikát na Vámi zadaný mail a zařadíme Vás do síně slávy!")}, 500);
      } else if (success === "false") {
        setTimeout(() => {popup("Něco se bohužel pokazilo. Nic se neděje, můžete se o objednávku znovu pokusit později.", true)}, 500); 
      }
    }
    called++;
  }, []);

  return (
    <>
      <AgressiveTimer></AgressiveTimer>
      <Header selectedNumber={selectedNumber} lastAcceptableValue={lastAcceptableValue} onChange={(v) => {setSelectedNumber(v);if(v){setLastAcceptableValue(v)}}}></Header>
      <Grandfather selectedNumber={lastAcceptableValue}></Grandfather>
      <Magie selectedNumber={lastAcceptableValue}></Magie>
      <CantWait selectedNumber={lastAcceptableValue}></CantWait>
      <HallOfFame selectedNumber={lastAcceptableValue}></HallOfFame>
      <Nothing selectedNumber={lastAcceptableValue}></Nothing>
      <Order setBuyerAddress1={setBuyerAddress1} buyerAddress1={buyerAddress1} setBuyerAddress2={setBuyerAddress2} buyerAddress2={buyerAddress2} setBuyerName={setBuyerName} buyerName={buyerName} buyerSurname={buyerSurname} setBuyerSurname={setBuyerSurname} popup={popup} setName={setName} name={name} email={email} setEmail={setEmail} selectedNumber={lastAcceptableValue}></Order>
      <Review selectedNumber={lastAcceptableValue}></Review>
      <Offer selectedNumber={lastAcceptableValue}></Offer>
      <YouHere></YouHere>
      <div className="Footnote">
      <a target="_blank" className="Footnote__a" href="terms.pdf">Obchodní podmínky</a>, <a target="_blank" href="GDPR.pdf" className="Footnote__a">ochrana osobních údajů</a> | <a target="_blank" className="Footnote__a" href="https://www.amniomweb.cz">© AmnioM Web s.r.o. </a> (IČO: 11667052) | <a target="_blank" className="Footnote__a" href="https://www.amniomweb.cz">Vytvořil AmnioM Web</a>
      </div>
      {popupText && <Popup isError={popupError} onDismiss={() => {setPopupText(null);setPopupError(false)}} text={popupText} ></Popup>}
    </>
  );
};
